<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "File Manager",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    // Get hak akses
    const menu = "File Manager";
    return {
      title: menu,
      isWrite: localStorage.getItem("isWrite"),
      file_manager: [],
      currentPage: 1,
      lastPage: 1,
      paginationLinks: [],
      backend_url: process.env.VUE_APP_BACKEND_URL,
      showModalAddFile: false,
      formData: {
        file: "",
        nama: "",
      },
    };
  },
  async mounted() {
    await this.getFiles();
  },
  methods: {
    async getFiles(page = 1) {
      const url = `${process.env.VUE_APP_BACKEND_URL_VERSION}master/file-manager?page=${page}`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
          },
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData.meta.code === 200) {
            this.file_manager = responseData.data.data;
            this.currentPage = responseData.data.current_page;
            this.lastPage = responseData.data.last_page;
            this.paginationLinks = responseData.data.links;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: responseData.meta.message,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    copyURL(url) {
      navigator.clipboard.writeText(url);
      alert("Path berhasil di copy");
    },
    copyTag(url) {
      const text = `<img src="${process.env.VUE_APP_BACKEND_URL + url}">`;
      navigator.clipboard.writeText(text);
      alert("Tag berhasil di copy");
    },
    onFileChange(event) {
      // Handle file selection change
      this.formData.file = event.target.files[0];
    },
    submitForm() {
      const formData = new FormData();
      formData.append("file", this.formData.file);
      formData.append("nama", this.formData.nama);

      axios
        .post(
          `${process.env.VUE_APP_BACKEND_URL_VERSION}master/file-manager`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.access_token}`,
            },
          }
        )
        .then((response) => {
          const responseData = response.data;
          if (responseData.meta.code === 200) {
            this.showModalAddFile = false; // Close the modal upon successful submission
            this.getFiles(); // Refresh file manager display
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: responseData.meta.message,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteFile(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/file-manager/" +
              id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getFiles();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    // deleteFile(id) {
    //   // Handle deleting file
    // },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="text-right mt-3">
      <button
        class="btn btn-success"
        @click="showModalAddFile = !showModalAddFile"
        v-if="isWrite == 1"
      >
        <i class="mdi mdi-plus me-2"></i>Add File
      </button>
    </div>
    <div class="row mt-4">
      <div
        class="col-lg-4 col-xl-3"
        v-for="file in file_manager"
        :key="file.id_file_manager"
      >
        <!-- Simple card -->
        <b-card :img-src="backend_url + file.url" img-alt="Card image" img-top>
          <h5>{{ file.nama }}</h5>
          <div class="btn-group">
            <a
              href="javascript: void(0);"
              class="btn btn-primary btn-sm font-16"
              @click="copyURL(file.url)"
            >
              <i class="mdi mdi-content-copy"></i>
              Copy PATH
            </a>
            <a
              href="javascript: void(0);"
              class="btn btn-success btn-sm font-16"
              @click="copyTag(file.url)"
            >
              <i class="mdi mdi-content-copy"></i>
              Copy Tag
            </a>
            <a
              href="javascript: void(0);"
              class="btn btn-danger btn-sm font-16"
              @click="deleteFile(file.id_file_manager)"
              v-if="isWrite == 1"
            >
              <i class="mdi mdi-delete"></i>
              Delete File
            </a>
          </div>
        </b-card>
      </div>
    </div>
    <nav aria-label="Page navigation">
      <ul class="pagination">
        <li class="page-item" v-if="currentPage > 1">
          <a
            class="page-link"
            href="javascript:void(0);"
            @click="getFiles(currentPage - 1)"
          >
            «
          </a>
        </li>
        <li
          class="page-item"
          v-for="page in paginationLinks"
          :key="page.label"
          :class="{ active: page.active }"
        >
          <a
            class="page-link"
            href="javascript:void(0);"
            @click="getFiles(page.label)"
            ><span v-html="page.label"></span
          ></a>
        </li>
        <li class="page-item" v-if="currentPage < lastPage">
          <a
            class="page-link"
            href="javascript:void(0);"
            @click="getFiles(currentPage + 1)"
          >
            »
          </a>
        </li>
      </ul>
    </nav>
    <!-- end row -->
    <b-modal v-model="showModalAddFile" title="Add File Manager" hide-footer>
      <form @submit.prevent="submitForm" class="needs-validation" novalidate>
        <div class="mb-3">
          <label for="name" class="form-label">File</label>
          <input
            type="file"
            @change="onFileChange"
            accept="image/*"
            class="form-control"
            id="name"
            required
          />
        </div>
        <div class="mb-3">
          <label for="name" class="form-label">Nama</label>
          <input
            type="text"
            v-model="formData.nama"
            class="form-control"
            id="name"
            required
          />
        </div>
        <div class="mb-3">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
</style>
